import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Title, Flex } from "../utils/system";

// Modules
import Hero from "../components/Hero";
import Modum from "../components/Modum";
import { One, Three } from "../components/Module";

import Values from "../components/Values";

const IndexPage = ({ data }) => {
  const {
    title,
    subtitle,
    problem,
    hero,
    modumSection,
    modules,

    values,
  } = data.sanityHome;
  return (
    <Layout>
      <Hero title={title} sub={subtitle} image={hero.asset.gatsbyImageData} />
      <Three data={problem} />
      <Modum data={modumSection} />
      <Values data={values} />
      {/* <Video url={video.asset.url} /> */}
      <Flex px={[5, 7]} py={4}>
        <Title fontSize={[5, 6]} textAlign="center">
          Examples of how Creo can help you
        </Title>
      </Flex>
      {modules &&
        modules.map((item, key) => <One data={item} key={key} number={key} />)}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query home {
    sanityHome {
      title
      subtitle
      problem
      hero {
        asset {
          gatsbyImageData(aspectRatio: 1.8)
        }
      }
      modumSection {
        title
        subTitle
        steps {
          title
          subTitle
          copy
        }
      }
      modules {
        title
        text
        image {
          asset {
            gatsbyImageData(aspectRatio: 1)
          }
        }
      }
      video {
        asset {
          url
        }
      }
      values {
        title
        description
        image {
          asset {
            gatsbyImageData(aspectRatio: 1)
          }
        }
      }
    }
  }
`;
