import React from "react";
import { Grid, Flex, Title, Text } from "../../utils/system";

const Service = ({ title, caption, text, number }) => (
  <Flex
    flexDirection="column"
    alignItems="flex-start"
    justifyContent="flex-start"
    // border="1px solid black"
    bg="pink"
    color="white"
    borderRadius={10}
    p={3}
  >
    <Flex
      bg="grey"
      color="black"
      // border="1px solid black"
      borderRadius="50%"
      size={50}
    >
      <Text>{number + 1}</Text>
    </Flex>
    <Title fontSize={4} mt={4} mb={0}>
      {title}
    </Title>
    <Text mt={0} color="#b31569" fontSize={3} lineHeight={1.4}>
      {caption}
    </Text>
    <Text fontSize={3} lineHeight={1.4}>
      {text}
    </Text>
  </Flex>
);

const Modum = ({ data }) => {
  const { title, subTitle, steps } = data;
  return (
    <Flex flexDirection="column" py={5} px={3} bg="grey" color="black">
      <Title mb={0} fontSize={[5, 7]}>
        {title}
      </Title>
      <Text
        mb={4}
        mt={0}
        color="pink"
        textAlign="center"
        px={4}
        fontSize={3}
        lineHeight={1.4}
      >
        {subTitle}
      </Text>
      <Grid
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"]}
        gridGap={4}
        width="95%"
        mt={4}
        mb={5}
      >
        {steps &&
          steps.map((item, key) => (
            <Service
              key={key}
              number={key}
              title={item.title}
              caption={item.subTitle}
              text={item.copy}
            />
          ))}
      </Grid>
      {/* <Link to="/products">
        <Flex
          bg="black"
          color="white"
          px={3}
          py={3}
          justifyContent="center"
          alignItems="center"
        >
          <Label m={0}>Products & Services -></Label>
        </Flex>
      </Link> */}
    </Flex>
  );
};

export default Modum;
