import React from "react";
import { Grid, Flex, Title, Text } from "../../utils/system";
import { GatsbyImage } from "gatsby-plugin-image";

const Hero = ({ title, sub, image }) => (
  <Flex flexDirection="column">
    <Flex height="100%" p={[4, 5]} mt={[3, 5]} alignItems="flex-end">
      <Grid
        gridTemplateColumns={["1fr", "1fr", "1fr", "4fr 1fr 2fr"]}
        style={{ alignItems: "end" }}
      >
        <Title fontSize={[5, 6]} m={0} color="black">
          {title}
        </Title>
        <Flex />
        <Flex>
          <Text fontSize={3} lineHeight={1.4}>
            {sub}
          </Text>
        </Flex>
      </Grid>
    </Flex>
    <GatsbyImage image={image} alt="Hero" />
    {/*
    <Box
      position="absolute"
      size={120}
      bg="blue"
      borderRadius="50%"
      top="66vh"
      left="50%"
      style={{ transform: "translate(-50%, -50%)" }}
    /> */}
  </Flex>
);

export default Hero;
