import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Grid, Flex, Title, Text, Box } from "../../utils/system";
import theme from "../../utils/theme";

const colors = Object.values(theme.colors);

export const One = ({ data, number }) => (
  <Grid gridTemplateColumns={["1fr", "1fr 1fr"]}>
    {number % 2 === 0 ? (
      <>
        <Flex>
          <GatsbyImage image={data.image.asset.gatsbyImageData} />
        </Flex>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          p={[4, 4, 5]}
        >
          <Title
            mb={2}
            mt={0}
            pr={[3, 3, 5]}
            fontSize={[5, 5, 6]}
            color={colors[number]}
          >
            {data.title}
          </Title>
          <Text fontSize={3}>{data.text}</Text>
        </Flex>
      </>
    ) : (
      <>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          p={[4, 4, 5]}
          order={[2, 1]}
        >
          {/* <Text fontSize={[1, 2]} mb={0} className="uppercase">
            {data.caption}
          </Text> */}
          <Title
            mb={2}
            mt={0}
            pr={[3, 3, 5]}
            fontSize={[5, 5, 6]}
            color={colors[number]}
          >
            {data.title}
          </Title>
          <Text fontSize={3}>{data.text}</Text>
        </Flex>

        <Flex order={[1, 2]}>
          <GatsbyImage image={data.image.asset.gatsbyImageData} />
        </Flex>
      </>
    )}
  </Grid>
);

export const Three = ({ data }) => (
  <Flex p={[4, 5]} flexDirection="column" alignItems="flex-start">
    <Title fontSize={[4, 5, 6]} pr={[0, 6]} m={0}>
      {data}
    </Title>
    <Grid mt={[5, 6, 7]} gridTemplateColumns={["1fr", "2fr 1fr"]}>
      <Flex justifyContent="flex-start">
        <Box size={[100, 150, 250]} borderRadius="50%" bg="yellow" />
        <Box
          size={[100, 150, 250]}
          borderRadius="50%"
          bg="green"
          ml={[-30, -80]}
        />
        <Box
          size={[100, 150, 250]}
          borderRadius="50%"
          bg="purple"
          ml={[-30, -80]}
        />
      </Flex>
      {/* <Box p={[2, 3]} mt={[5, 0]}>
        <Text fontSize={3}>
          Creo offers a scalable collaborative method to empower, identify, grow
          and sustain creative ways to solve problems, provide vision and impact
          the balance sheet within business constraints. Working at the
          intersection of art and science we build creative cultures that people
          and clients love.{" "}
        </Text>
      </Box> */}
    </Grid>
  </Flex>
);
