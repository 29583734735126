import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Flex, Grid, Text, Label, Title, Box } from "../../utils/system";

const Values = ({ data }) => {
  const values = data;
  return (
    <Flex flexDirection="column" mt={5}>
      <Title fontSize={7}>Our Values</Title>
      <Grid
        mb={5}
        gridTemplateColumns={["1fr", "1fr 1fr", "1fr 1fr 1fr"]}
        gridGap={4}
      >
        {values &&
          values.map((value, index) => (
            <Grid
              color="white"
              justifyContent="flex-start"
              alignItems="flex-start"
              key={index}
            >
              <GatsbyImage
                image={value.image.asset.gatsbyImageData}
                alt={value.title}
                layout="fullWidth"
                style={{
                  gridArea: "1/1",
                }}
              />
              <Box
                bg="rgba(0,0,0,0.3)"
                style={{
                  // By using the same grid area for both, they are stacked on top of each other
                  gridArea: "1/1",
                  position: "relative",
                  // This centers the other elements inside the hero component

                  display: "grid",
                }}
              >
                <Flex flexDirection="column" p={4} alignItems="center">
                  <Label fontSize={5} textAlign="center">
                    {value.title}
                  </Label>
                  <Text>{value.description}</Text>
                </Flex>
              </Box>
            </Grid>
          ))}
      </Grid>
    </Flex>
  );
};

export default Values;
